import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UseCase } from '@api/interactors/UseCase';
import { PutResource } from '@api/resources';
import { PFCHttpService } from '@api/services';
import { Patron } from '../models/patron';

@Injectable()
export class UpdatePatron implements UseCase<Patron, Observable<Patron>> {
  private putResource: PutResource<Patron.Rel, Patron, Patron>;

  constructor(private http: PFCHttpService) {
    this.putResource = new PutResource<Patron.Rel, Patron, Patron>(http);
  }

  execute(patron: Patron): Observable<Patron> {
    return this.putResource.execute({
      parent: patron,
      rel: 'put',
      body: patron
    });
  }
}

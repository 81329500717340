import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { UseCase } from '@api/interactors/UseCase';
import { Api } from '@api/models';
import { GetApi, GetResourceWithParams } from '@api/resources';
import { PFCHttpService } from '@api/services';
import { PageableParamsWithFilters } from '@core/data-sources/PageableParams';
import { PatronFilters } from '../models/patron-filters';
import { PatronsList } from '../models/patrons-list';

@Injectable()
export class GetPatrons implements
  UseCase<PageableParamsWithFilters<PatronFilters>, Observable<PatronsList>> {
  private getApi: GetApi;
  private getResource: GetResourceWithParams<Api.Rel, PatronsList>;

  constructor(private http: PFCHttpService) {
    this.getApi = new GetApi(http);
    this.getResource = new GetResourceWithParams<Api.Rel, PatronsList>(http);
  }

  execute({ page, size, sort, filters }: PageableParamsWithFilters<PatronFilters>):
    Observable<PatronsList> {
    return this.getApi.execute().pipe(flatMap(api => {
      return this.getResource.execute({
        parent: api,
        rel: 'patrons',
        queryParams: {
          page,
          size,
          sort,
          patronId: filters.patronId,
          agent1LastName: filters.agent1LastName,
          agent1FirstName: filters.agent1FirstName,
          activated: filters.activated,
          farmName: filters.farmName,
          city: filters.city,
          coopId: filters.coopId
        }
      });
    }));
  }
}

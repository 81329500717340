import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { UseCase } from '@api/interactors/UseCase';
import { GetResource } from '@api/resources';
import { PFCHttpService } from '@api/services';
import { GetPatron } from '../../interactors/getPatron';
import { Patron } from '../../models/patron';
import { ForwardContractParticipation } from '../models/forward-contract-participation';

@Injectable()
export class GetFcParticipations implements UseCase<number, Observable<ForwardContractParticipation[]>> {
  private getResource: GetResource<Patron.Rel, ForwardContractParticipation[]>;
  private getPatron: GetPatron;

  constructor(private http: PFCHttpService) {
    this.getResource = new GetResource<Patron.Rel, ForwardContractParticipation[]>(http);
    this.getPatron = new GetPatron(http);
  }

  execute(patronId: number): Observable<ForwardContractParticipation[]> {
    return this.getPatron.execute(patronId).pipe(flatMap(patron => {
      return this.getResource.execute({
        parent: patron,
        rel: 'patron-fc-participations'
      });
    }));
  }
}

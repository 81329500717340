import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageableDataSourceLoader } from '@core/data-sources';
import { CreatePatron, GetPatron, GetPatrons, GetPatronsByCoopAndPatronId, UpdatePatron } from '../interactors';
import { Patron } from '../models/patron';
import { PatronFilters } from '../models/patron-filters';
import { PatronsList } from '../models/patrons-list';

@Injectable()
export class PatronsService implements PageableDataSourceLoader<Patron, PatronFilters> {
  constructor(
    private _getPatronsByCoopAndPatronIdId: GetPatronsByCoopAndPatronId,
    private _getPatrons: GetPatrons,
    private _createPatron: CreatePatron,
    private _updatePatron: UpdatePatron,
    private _getPatron: GetPatron
  ) {}

  loadPage(sortDirection: string, pageIndex: number, pageSize: number, filters: PatronFilters):
    Observable<PatronsList> {
    return this._getPatrons.execute({
      page: pageIndex,
      size: pageSize,
      sort: sortDirection,
      filters
    });
  }

  getPatron(id: number): Observable<Patron> {
    return this._getPatron.execute(id);
  }

  getPatronsBy(coopId: number, patronId: number): Observable<Patron[]> {
    return this._getPatronsByCoopAndPatronIdId.execute({ coopId, patronId });
  }

  createPatron(patron: Patron): Observable<Patron> {
    return this._createPatron.execute(patron);
  }

  updatePatron(patron: Patron): Observable<Patron> {
    return this._updatePatron.execute(patron);
  }

  calculateMonthlyAutoValue(monthProd: number, monthlyPercentage: number): string | undefined {
    if (monthProd > 1 && monthlyPercentage > 0.01) {
      return (monthProd * (monthlyPercentage / 100)).toFixed(2);
    } else {
      return undefined;
    }
  }

  calculateAnnualAutoValue(monthProd: number, monthlyPercentage: number): string | undefined {
    if (monthProd > 1 && monthlyPercentage > 0.01) {
      return (12 * monthProd * (monthlyPercentage / 100)).toFixed(2);
    } else {
      return undefined;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'auditActionLabel'
})

export class AuditActionLabelPipe implements PipeTransform {
  transform(action: string): string {
    switch (action) {
      case 'add': return 'Created';
      case 'mod': return 'Updated';
      default: return undefined;
    }
  }
}
